<template>
  <div class="home">
    <div class="searchNav">
      <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        :loading="loading"
        @click="openGrouplist"
        >新建订阅</el-button
      >
      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.keyword"
          placeholder="订阅名称"
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <el-tabs v-model="activeName" class="tabs">
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="height"
        v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column prop="CompanyName" label="所属公司" width="250">
        </el-table-column> -->
        <el-table-column prop="GroupName" label="订阅名称"> </el-table-column>
        <el-table-column prop="count" label="订阅用户条数"> </el-table-column>
        <el-table-column prop="CreateTime" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.CreateTime?.substring(0, 10) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              class="linkmargin"
              :underline="false"
              @click="handleEdit(scope.row)"
              >编辑
            </el-link>
            <el-link
              type="primary"
              class="linkmargin"
              :underline="false"
              @click="handleView(scope.row)"
              >查看成员
            </el-link>
            <el-link
              type="primary"
              :underline="false"
              class="linkmargin"
              @click="handleQrcode(scope.row)"
              >分享
            </el-link>
            <el-link
              type="primary"
              :underline="false"
              class="linkmargin"
              :disabled="scope.row.count > 0"
              @click="handleDel(scope.row)"
              >删除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </el-tabs>
    <el-dialog
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="450px"
    >
      <div style="display: flex">
        <div class="codebox">
          <h3 style="font-weight: bolder">请使用微信扫一扫</h3>

          <h3 style="margin-top: -120px; font-weight: bolder">
            订阅 [ {{ titleName }} ] 组的签收信息
          </h3>
        </div>
        <vue-qr
          class="box"
          :text="codeValue"
          :logoScale="0.2"
          :size="200"
        ></vue-qr>
      </div>
    </el-dialog>
    <Grouplist ref="Grouplist" :parentObj="{ ...this }" />
    <EditForm ref="editForm" />
  </div>
</template>

<script>
const downloadFile = (url, downloadName = "") => {
  // console.log(url);
  const link = document.createElement("a");
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      link.href = URL.createObjectURL(blob);
      // console.log(link.href)
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
import radioGroup from "@/components/CompanyRadioGroup.vue";
import VueQr from "vue-qr";
import EditForm from "@/components/GroupRecording.vue";
import Grouplist from "@/components/Grouplist"; //新增群组
export default {
  name: "Home",
  components: {
    radioGroup,
    EditForm,
    VueQr,
    Grouplist,
  },
  data() {
    return {
      activeName: "first",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      queryParam: { SupplieId: "全部", Status: "0" },
      keyword: "",
      loading: false,
      dialogVisible: false,
      templateId: "",
      templateList: [],
      titleName: "",
      codeValue: "",
      ip: "",
    };
  },
  mounted() {
    // this.getTemplateList()
    this.ip = sessionStorage.getItem("ip");
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    handleEdit(row) {
      this.$refs.Grouplist.openForm(row.CompanyId, row);
    },
    // 删除
    handleDel(row) {
      this.$confirm("此操作将删除当前数据,确认继续吗", "提示", {
        type: "warning",
        callback: (val) => {
          if (val == "confirm") {
            this.$http
              .post("/BO/CustomerinfoGroup/DeleteData", [row.Id])
              .then((res) => {
                if (res.Success) {
                  this.getDataList();
                  this.$message.success("操作成功");
                } else {
                  this.$message.error(res.Msg);
                }
              });
          }
        },
      });
    },
    // 多选
    handleSelectionChange(rows) {
      console.log(rows);
    },

    //新增
    openGrouplist() {
      console.log("打开群组列表");

      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$message.error("请选择公司");
        return;
      }
      this.$refs.Grouplist.openForm(this.queryParam.SupplieId);
    },
    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    // 公司切换
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    handleQrcode(row) {
      this.codeValue = row.QrCode;
      this.titleName = row.GroupName;
      this.dialogVisible = true;
    },
    handleView(row) {
      this.$refs.editForm.openForm(row.Id);
    },

    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    gridDateFormatter(row, column, cellValue, index) {
      const daterc = row[column.property];
      if (daterc) {
        const dateMat = new Date(daterc);
        const Y = dateMat.getFullYear() + "-";
        const M =
          dateMat.getMonth() + 1 < 10
            ? "0" + (dateMat.getMonth() + 1) + "-"
            : dateMat.getMonth() + 1 + "-";
        const D =
          dateMat.getDate() < 10
            ? "0" + dateMat.getDate() + " "
            : dateMat.getDate() + " ";

        const H =
          dateMat.getHours() < 10
            ? "0" + dateMat.getHours() + ""
            : dateMat.getHours() + ":";

        const F =
          dateMat.getMinutes() < 10
            ? "0" + dateMat.getMinutes() + ""
            : dateMat.getMinutes() + "";

        const S =
          dateMat.getSeconds() < 10
            ? "0" + dateMat.getSeconds() + ""
            : dateMat.getSeconds() + "";

        return Y + M + D + H + F;
      }
    },

    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/CustomerinfoGroup/GetGroupDetail", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-menu-item.is-active {
    color: #1aad19;
}
/deep/ .el-menu-item.is-active {
    color: #1aad19;
}
.el-button--primary {
    color: #FFF;
    background-color: #1aad19;
    border-color: #1aad19;
}
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #FFF;
    background-color: #1aad19;
    border-color: #1aad19;
}
/deep/.el-radio-button__inner:hover {
    color: #1aad19;
}
/deep/ .el-tabs__nav {
  width: 100%;
  background: #fff;
}
.codebox {
  font-size: 18px;
  margin: -20px 0 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.box {
  display: flex;
  margin-top: 21%;
  width: 50%;
  margin-left: -75%;
}

/deep/ .el-tab-pane {
  width: 100%;
  background: #fff;
}
/deep/ .el-tabs__item {
  width: 16.6%;
  text-align: center;
  font-weight: bold;
  padding: 0;
}
/deep/ .el-tabs__item:hover {
  background: #ecf5ff;
}
/deep/.el-dialog {
  padding: 1vw;
  font-weight: bolder;
  height: 400px;
  border-radius: 8px;
}
/deep/.el-dialog__body {
  padding: 30px 10px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
</style>
